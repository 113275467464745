import { ThemeProvider } from '@mui/material'
import React from 'react'
import { overmoonTheme } from '../../theme'

type OvermoonThemeProviderProps = {
  children: React.ReactNode
}

const OvermoonThemeProvider = (props: OvermoonThemeProviderProps) => {
  const { children } = props
  return <ThemeProvider theme={overmoonTheme}>{children}</ThemeProvider>
}

export default OvermoonThemeProvider
